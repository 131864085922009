@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.wrapWord {
  white-space: normal !important;
  overflow: hidden;
  text-overflow: clip;
}

.wrap-header {
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* This ensures your container takes up the full viewport height */
}

.dataAboveGrid {
  flex: 1; /* Takes up 1/4 of the space */
  overflow: auto; /* Just in case your content is longer than the available space */
}

.dataGridContainer {
  flex: 3; /* Takes up the remaining 3/4 of the space */
}
