/* styles.css */

:root {
  --primary-color: #1f2a40; /* Define a fallback primary color in case the theme color is not available */
}

.dialogContent {
  background-color: var(
    --primary-color
  ); /* Use the primary color from the root */
  padding: 20px;
}

.dialogTitle {
  background-color: var(
    --primary-color
  ); /* Use the primary color from the root */
  padding: 20px;
}

.textField {
  background-color: var(
    --primary-color
  ); /* Use the primary color from the root */
  color: 'white';
}

.shared-button {
  font-size: 14px;
  font-weight: bold;
  margin-top: 16px;
  padding: 10px 20px;
}

.wrap-header {
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.flashing-text {
  animation: flash 1s linear infinite;
}
